#promisesPanelContainer {
    padding-top: 24px;
    margin-bottom: 12px;
}

.promisesBoxes {
    color: var(--white);
    border-radius: 8px;
    padding: 20px;
}

.yellowPromisesBox {
    background-color: var(--rubberDucky);
}

.redPromisesBox {
    background-color: var(--strawberryDessert);
}

.bluePromisesBox {
    background-color: var(--hotAirBalloon);
}

#promisesBoxesContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

@media (min-width: 800px) {

    #promisesPanelContainer {
        padding: 2.5vw 5vw; 
    }

    #promisesBoxesContainer {
        flex-direction: row;
        gap: 2.5vw;
    }

}