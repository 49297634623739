#profileImage {
    width: 100%;
}

#brandStoryBlueContainer {
    background-color: var(--hotAirBalloon);
    margin-top: 12px;
    padding-bottom: 12px;
}

#brandStoryWhiteContainer {
    background-color: var(--white);
    margin: 20px;
    padding: 20px;
    border-radius: 8px;
}

@media (min-width: 800px) {
    #brandStoryBlueContainer {
        display: flex;
        flex-direction: row;
        padding: 0;
    }

    #profileImage {
        width: 50vw;
        object-fit: cover;   
    }

    #brandStoryWhiteContainer {
        margin: 5vw;
        padding: 2.5vw;;
    }

   


}