/* proxima nova extra condensed */
@import url("https://use.typekit.net/etq6kvb.css");
/* acumin variable */
@import url("https://use.typekit.net/etq6kvb.css");

:root {
    --hotAirBalloon: #2e82b1;
    --rubberDucky: #f4b826;
    --strawberryDessert: #f83c4b;
    --white: #ffffff;
}

body {
    margin: 0;
    padding: 0;
    color: var(--hotAirBalloon);
}

body {
    font-family: "acumin-variable", sans-serif;
    font-weight: normal;
    /* font-variation-settings: "slnt" 0, "wdth" 100, "wght" 194.2857; */
}

.hotAirBalloon {
    color: var(--hotAirBalloon);
}

.rubberDucky {
    color: var(--rubberDucky);
}

.strawberryDessert {
    color: var(--strawberryDessert);
}

.white {
    color: var(--white);
}

.consistentPadding {
    padding: 10px 20px;
}

.whiteBar {
   background-color: var(--white);
   height: 8px;
   width: 150px;
   margin-bottom: 32px;
}

.blueBar {
    background-color: var(--hotAirBalloon);
    height: 8px;
    width: 150px;
    margin-bottom: 32px;
}

a {
    text-decoration: none;
    color: inherit;
}

h1, h2, h3, h4, h5, h6 {
    font-family: "proxima-nova-extra-condensed", sans-serif;
    font-weight: bold;
    font-style: normal;
    line-height: 1.2;
    margin-bottom: 16px;
}

h1 {
    font-size: 48px;
    margin-top: 24px;
}

h2 {
    font-size: 40px;
    margin-top: 0px;
}

h3 {
    font-size: 32px;
    margin-top: 0px;
}

h4 {
    font-size: 24px;
}

h5 {
    font-size: 16px;
}

h6 {
    font-size: 8px;
}

p {
    line-height: 1.5;
    margin-bottom: 0;
    font-size: 16px;
}


@media (min-width: 800px) {
    p {
        font-size: 18px;
    }
}