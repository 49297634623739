#carouselInnerContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

#carouselImagesContainer {
    position: relative;
    margin: 0 auto;
    margin-top: 40px;

}

#carouselArrowLeft {
    border: solid var(--strawberryDessert);
    ;
    border-width: 0 4px 4px 0;
    border-radius: 2px;
    padding: 6px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

#carouselArrowRight {
    border: solid var(--strawberryDessert);
    ;
    border-width: 0 4px 4px 0;
    border-radius: 2px;
    padding: 6px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

#hiddenImage {
    width: 50vw;
    visibility: hidden;
}

.carouselImage {
    width: 50vw;
    border-radius: 4px;
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translate(0, -50%);
}

.leftImage {
    transform: translate(-35%, -50%) scale(.75);
    z-index: 1;
}

.rightImage {
    transform: translate(35%, -50%) scale(.75);
    z-index: 1;
}

.featuredImage {
    z-index: 2;
}

#dotsContainer {
    display: flex;
    gap: 6px;
    justify-content: center;
    margin-top: 40px;
}

.dots {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: var(--strawberryDessert);
}

@media (min-width: 800px) {
    #heroContainer {
        display: flex;
        flex-direction: row;
        padding: 2.5vw 5vw;
        justify-content: space-between;
        align-items: center;
    }

    #intro {
        width: 35%;
    }

    #intro>p {
        margin-left: 0;
    }

    #carouselOuterContainer {
        width: 45vw;
    }

    #hiddenImage {
        width: 20vw;
    }

    .carouselImage {
        width: 20vw;
    }

    .leftImage {
        transform: translate(-60%, -50%) scale(.75);
      
    }
    
    .rightImage {
        transform: translate(60%, -50%) scale(.75);
    }

    .dots {
        height: 10px;
        width: 10px;
    }
}