#navLogo {
    width: 50px;
}

#navContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    background-color: var(--rubberDucky);
}

#burgerMenu {
    width: 50px;
}

.headerFooterCompanyName {
    font-family: "proxima-nova-extra-condensed", sans-serif;
    font-weight: bold;
    font-style: normal;
    margin: 0;
}

@media (min-width: 800px) {

    #navContainer {
        padding-left: 5vw;
        padding-right: 5vw;
    }
}