#creatorSpotlightContainer {
    background-color: var(--strawberryDessert);
    color: var(--white);
    padding-top: 24px;
}

#creatorsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin: 24px 0;
}

.creator {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 8px;
}

.creator > p {
    font-family: "proxima-nova-extra-condensed", sans-serif;
    font-size: 24px;
    margin: 0;
}

.creatorProfilePicture {
    width: 120px;
    border-radius: 50%;
    border: 8px solid var(--white);
    
}

@media (min-width: 800px) {
    #creatorSpotlightContainer {
        padding: 2.5vw 5vw; 
    }

    #creatorTextContainer {
        max-width: 600px;
    }
}

@media (min-width: 1200px) {

    #creatorSpotlightContainer {
        display: flex;
        justify-content: space-between;
        gap: 2.5vw;
    }

    #creatorsContainer {
        gap: 2.5vw;
        flex-wrap: nowrap;
    }
}
