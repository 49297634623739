#footer {
    background-color: var(--rubberDucky);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
}

#footerLogo {
    width: 25px;
}

@media (min-width: 800px) {

    #footer {
        padding-left: 5vw;
        padding-right: 5vw;
    }
}